import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { register } from "store/actions/registration";
import { bmw } from "store/actions/authentication";
import { get, join } from "lodash";
function SignUp(props) {
  const [email, setEmail] = useState("");
  // const [code, setCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTermsError, setAgreeTermsError] = useState();

  useEffect(() => {
    document.body.classList.add("authentication");
  }, []);

  useEffect(() => {
    let timer;
    if (props.registration?.response?.reader) {
      timer = setTimeout(() => {
        // props.history.push("/");
        props.history.replace("/");
        // props.history.push({
        //   pathname: "/",
        //   state: { fromDashboard: true },
        // });
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [props.registration?.response?.reader, props.history]);
  // useEffect(() => {
  //   const url = "https://clubs.ffgolf.org/xmlshare/xmlCheckLicense.php";
  //   const ymd = moment().format("YMMDD");
  //   const key = `bmw2016@FFG$${ymd}`;

  //   // const licence = "43891143";
  //   const licence = "438911435";
  //   const instance = axios.create({
  //     baseURL: "https://clubs.ffgolf.org/xmlshare/xmlCheckLicense.php",
  //     headers: {},
  //   });
  //   instance.defaults.headers.common = {};
  //   instance
  //     .get(`?key=${MD5(key)}&licence=${licence}`)
  //     .then((resp) => {
  //       //     const data = `<?xml version="1.0" encoding="utf-8" standalone="yes"?>
  //       // <licencie>
  //       //   <active>O</active>
  //       //   <licence>43891143</licence>
  //       //   <civilite>M.</civilite>
  //       //   <nom>KEENE</nom>
  //       //   <prenom>Patrick</prenom>
  //       //   <date_naissance>20/06/1945</date_naissance>
  //       //   <index>34.3</index>
  //       //   <djf_date_fin></djf_date_fin>
  //       // </licencie>`;
  //       const { data } = resp;
  //       parseStringPromise(data, {
  //         explicitArray: false,
  //         valueProcessors: [
  //           (v, p) => {
  //             if (p === "active") {
  //               return Boolean(v);
  //             }
  //             return v;
  //           },
  //         ],
  //       })
  //         .then((result) => {
  //           const { licencie } = result;

  //           if (licencie) {
  //             const gender =
  //               indexOf(["m.", "mr."], licencie.civilite.toLowerCase()) !== -1
  //                 ? "male"
  //                 : "female";
  //             const birthday = moment(licencie.date_naissance, "DD/MM/YYYY");
  //             const reader = {
  //               enabled: licencie.active,
  //               lastName: licencie.nom,
  //               firstName: licencie.prenom,
  //               code: licencie.licence,
  //               gender: gender,
  //               email: `${licencie.licence}@propress.fr`,
  //               plainPassword: licencie.licence,
  //               birthday: birthday.format("YYYY-MM-DD"),
  //             };
  //             return reader;
  //           }
  //           throw new Error(
  //             result?.licence?.message ||
  //               "Erreur lors de la verification de votre licence"
  //           );
  //         })
  //         .then((reader) => {})
  //         .catch((err) => {
  //           // Failed
  //           // console.log(err);
  //         });
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // }, []);

  const register = (e) => {
    e?.preventDefault();
    if (!agreeTerms) {
      setAgreeTermsError("Veuillez accepter les mentions légales.");
    } else {
      setAgreeTermsError(null);
      const data = {
        email,
        // code,
        firstName,
        lastName,
        plainPassword: {
          first: password,
          second: confirmPassword,
        },
        kiosk: process.env.REACT_APP_KIOSK_ID,
      };
      props.register(data);
    }
  };
  const getFieldError = (field) =>
    join(
      get(props.registration?.response?.errors?.form?.children, field)?.errors,
      "/n"
    );
  // const getFieldError = (field) =>
  //   join(
  //     props.registration?.response?.errors?.form?.children[field]?.errors,
  //     "/n"
  //   );

  const emailError = getFieldError("email");
  // const codeError = getFieldError("code");
  const firstNameError = getFieldError("firstName");
  const lastNameError = getFieldError("lastName");
  const passwordError = getFieldError("plainPassword.children.first");
  const confirmationPasswordError = getFieldError(
    "plainPassword.children.second"
  );
  return (
    <div className="container-fluid h-100 authentication mb-0">
      <div className="row p-0 h-100">
        <div className="col-md-7 col-sm-4 random-image"></div>
        <div className="col-12 col-md-5 col-sm-8">
          <div className="paper">
            <i className="fa fa-lock p-3 rounded-circle border-secondary border text-secondary"></i>
            <h1 className="text-secondary">Inscription</h1>

            <form className="form" method="post" onSubmit={register} noValidate>
              {props.registration?.response?.message && (
                <div className="alert alert-success">
                  {props.registration?.response?.message}
                </div>
              )}
              {!props.registration?.response?.reader && (
                <>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label
                          htmlFor="registration_form_firstName"
                          className="required"
                        >
                          Prénom
                        </label>
                        <input
                          type="text"
                          id="registration_form_firstName"
                          required
                          className={`form-control flat ${
                            firstNameError ? "is-invalid" : ""
                          }`}
                          disabled={props.registration.fetching}
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          autoComplete="cc-given-name"
                        />
                        {firstNameError && (
                          <div className="invalid-feedback">
                            {firstNameError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label
                          htmlFor="registration_form_lastName"
                          className="required"
                        >
                          Nom
                        </label>
                        <input
                          type="text"
                          id="registration_form_lastName"
                          required
                          className={`form-control flat ${
                            lastNameError ? "is-invalid" : ""
                          }`}
                          disabled={props.registration.fetching}
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          autoComplete="cc-family-name"
                        />
                        {lastNameError && (
                          <div className="invalid-feedback">
                            {lastNameError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <label htmlFor="inputEmail">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="inputEmail"
                    className={`form-control flat ${
                      emailError ? "is-invalid" : ""
                    }`}
                    required
                    disabled={props.registration.fetching}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                  <label htmlFor="inputPassword">Mot de paasse</label>
                  <input
                    type="password"
                    autoComplete="new-password"
                    id="inputPassword"
                    className={`form-control flat ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    disabled={props.registration.fetching}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && (
                    <div className="invalid-feedback">{passwordError}</div>
                  )}
                  <label htmlFor="inputPassword">
                    Confirmation mot de passe
                  </label>
                  <input
                    type="password"
                    autoComplete="new-password"
                    id="inputConfirmPassword"
                    className={`form-control flat ${
                      confirmationPasswordError ? "is-invalid" : ""
                    }`}
                    disabled={props.registration.fetching}
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {confirmationPasswordError && (
                    <div className="invalid-feedback">
                      {confirmationPasswordError}
                    </div>
                  )}

                  <div className="form-group mt-2">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="registration_form_agreeTerms"
                        name="registration_form[agreeTerms]"
                        required="required"
                        className={`form-check-input ${
                          agreeTermsError ? "is-invalid" : ""
                        }`}
                        value={agreeTerms}
                        onChange={(e) => {
                          setAgreeTerms(e.target.checked);
                          if (e.target.checked) {
                            setAgreeTermsError(null);
                          }
                        }}
                        disabled={props.registration.fetching}
                      />

                      <label
                        className="form-check-label required"
                        htmlFor="registration_form_agreeTerms"
                      >
                        <a
                          href="https://cgu.kioskpress.fr/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Mentions légales/Conditions générales
                        </a>
                      </label>
                      {agreeTermsError && (
                        <div className="invalid-feedback">
                          {agreeTermsError}
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-100 btn btn-lg btn-secondary flat my-2"
                  >
                    Inscription
                  </button>

                  <div className="row my-2 p-0 text-center">
                    <div className="col-12  ">
                      <Link className="text-dark" to="/signin">
                        Vous avez déjà un compte ?
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { registration } = state;

  return {
    registration,
  };
};
export default connect(mapStateToProps, { register, bmw })(SignUp);
