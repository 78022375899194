import { Issue } from "components";
import { useInfiniteScroll } from "config";
import readerUrl from "config/readerUrl";
import { first, map } from "lodash";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadPublication, loadPublicationIssues } from "store/actions/kiosk";

function Publication(props) {
  const { id: publicationId } = useParams();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useInfiniteScroll(() => {
    setIsFetching(false);
    dispatch(loadPublicationIssues(publicationId, true));
  });

  useEffect(() => {
    dispatch([
      loadPublication(publicationId),
      loadPublicationIssues(publicationId),
    ]);
  }, [dispatch, publicationId]);

  const publication = useSelector(
    (state) => state.entities.publications[publicationId]
  );

  const paginationPublicationIssuess = useSelector(
    (state) => state.pagination.publicationIssues[publicationId]
  );
  const issue = useSelector(
    (state) => state.entities.issues[first(paginationPublicationIssuess?.ids)]
  );

  const user = useSelector((state) => state?.profile?.user);

  const onClick = (issueItem) => {
    if (user && issueItem) {
      window.location.href = readerUrl(issueItem);
    } else {
      props.history.push("/signin");
    }
  };
  const subscription_price = 4.5;
  return (
    <div className="container my-5">
      <div className="d-flex row align-items-center">
        <div className="col-12 col-sm-12 col-md-3">
          <img
            alt={publication?.name}
            data-not-found
            className="img img-fluid m-3 shadow"
            src={issue?.coverUrl}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-9">
          <div className="ms-2">
            <div className="my-2">
              <div className="row">
                <div className="col-12 col-md-9">
                  <h1 className="text-secondary">Lisez {publication?.name}</h1>
                  <h5>
                    {issue?.publishedAt}{" "}
                    {issue?.number ? " - n°" + issue.number : ""}
                  </h5>
                </div>
                <div className="col-12 col-md-3">
                  <div
                    className="btn px-0 text-secondary"
                    onClick={() => {
                      onClick(issue);
                    }}
                  >
                    <i className="fa fa-book-reader" />
                    &nbsp;
                    <span className="font-weight-bold">Lire la parution</span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="p-3 my-2 shadow">
              <div className="row  align-items-center">
                <div className="col-12 col-md-9">
                  <h4>Inclus dans l'Offre Premium à {subscription_price}€</h4>
                  <p className="text-muted font-weight-bold">
                    Kiosque Premium : lisez sans compter vos magazines préférés
                    dans votre kiosque dédié.
                  </p>
                </div>

                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-column justify-content-center align-items-center">
                    <a
                      href="{ path('authentication_sign_in',{'_target_path': app.request.requestUri }) }"
                      className="btn btn-outline-secondary flat"
                    >
                      <span className="font-weight-bold">
                        1€ le premier mois
                      </span>
                      <br />
                      <small>puis {subscription_price}€/mois</small>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <a
                  href="{ path('authentication_sign_in',{'_target_path': app.request.requestUri }) }"
                  className="col-12 col-md-6 mt-2 px-0 btn text-secondary"
                  type="submit"
                >
                  <span className="font-weight-bold">
                    Acheter ce numéro pour seulement {publication?.price}€
                  </span>
                  <br />
                </a>
                <a
                  href="{ path('authentication_sign_in',{'_target_path': app.request.requestUri }) }"
                  className="col-12 col-md-6 mt-2 px-0 btn text-dark"
                >
                  <span className="font-weight-bold">
                    Payer avec votre téléphone {publication?.price * 1.2}€
                  </span>
                  <br />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="my-4">
        <h4 className="text-center text-secondary">Les anciens numéros</h4>
        <div className="row category m-0">
          {map(paginationPublicationIssuess?.ids, (issueId, i) => {
            return (
              <div className="col-6 col-md-2 p-1" key={i}>
                <Issue
                  issueId={issueId}
                  onClick={(e) => {
                    // console.log(issueId);
                    // window.location.href = readerUrl(props.issues[issueId]);
                    onClick(props.issues[issueId]);
                  }}
                />
              </div>
            );
          })}
          {isFetching && <div className="row">Loading ....</div>}
        </div>
      </div>

      {/* <div className="my-4">
        <h4 className="text-center text-secondary">
          Les lecteurs du quotidien Aujourd'hui en France ont également aimé
        </h4>
        <div className="row category m-0">
          {map(range(6), (item, i) => {
            return (
              <div className="col-6 col-md-2 p-1" key={i}>
                <Issue issueId={59131}  />
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
  // return <div className="min-vh-100"></div>;
}

const mapStateToProps = (state) => {
  const {
    entities: { categories, categoryItems, issues },
    pagination: { kioskCategories },
  } = state;

  return {
    categories,
    categoryItems,
    issues,
    isFetching:
      kioskCategories[process.env.REACT_APP_KIOSK_ID]?.isFetching || false,
  };
};
export default connect(mapStateToProps)(Publication);
