import App_Store from "images/Download_on_the_App_Store_Badge_FR_RGB_wht_100217.svg";
import Google_Play from "images/google-play-badge.png";

function DownloadOnTheStore() {
  return null;
  return (
    <div className="container my-2">
      <div className="pt-2">
        <h5 className="text-center">Télécharger l'application </h5>
        <h5 className="text-center">
          <u>LKN</u>
          <small>
            <em className="small">&nbsp;by ProPress</em>
          </small>
        </h5>

        <div className="d-flex justify-content-center align-items-center">
          <a href="https://testflight.apple.com/join/KrGQnFN1">
            <img
              src={App_Store}
              alt="Disponible sur l'App Store"
              className="img img-fluid"
              style={{ width: 170 }}
            />
          </a>
          <a href="/">
            <img
              src={Google_Play}
              alt="Disponible sur Google Play"
              className="img img-fluid"
              style={{ width: 200 }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default DownloadOnTheStore;
