import * as KioskActionTypes from "../actions/kiosk";

import merge from "lodash/merge";
import paginate from "./paginate";
import kioskCategory from "./kioskCategory";

import { combineReducers } from "redux";

// Updates an entity cache in response to any action with response.entities.
export const entities = (
  state = {
    publications: {},
    issues: {},
    categories: {},
    categoryItems: {},
  },
  action
) => {
  if (action.response && action.response.entities) {
    state = merge({}, state, action.response.entities);
  }
  return state;
};

// Updates the pagination data for different actions.
export const pagination = combineReducers({
  // recipesByCookTime: paginate({
  //   mapActionToKey: (action) => action.cookTime.toString(),
  //   types: [
  //     RecipectionTypes.RECIPES_BY_COOK_TIME_RECIPES_REQUEST,
  //     RecipectionTypes.RECIPES_BY_COOK_TIME_RECIPES_SUCCESS,
  //     RecipectionTypes.RECIPES_BY_COOK_TIME_RECIPES_FAILURE,
  //   ],
  // }),
  // recipesByCategory: paginate({
  //   mapActionToKey: (action) => action.categoryId.toString(),
  //   types: [
  //     RecipectionTypes.RECIPES_BY_CATEGORY_REQUEST,
  //     RecipectionTypes.RECIPES_BY_CATEGORY_SUCCESS,
  //     RecipectionTypes.RECIPES_BY_CATEGORY_FAILURE,
  //   ],
  // }),
  kioskCategories: kioskCategory({
    mapActionToKey: (action) => action.kioskId.toString(),
    types: [
      KioskActionTypes.KIOSK_CATEGORIES_REQUEST,
      KioskActionTypes.KIOSK_CATEGORIES_SUCCESS,
      KioskActionTypes.KIOSK_CATEGORIES_FAILURE,
    ],
  }),
  categoryItems: paginate({
    mapActionToKey: (action) => action.categoryId.toString(),
    types: [
      KioskActionTypes.KIOSK_CATEGORY_ITEMS_REQUEST,
      KioskActionTypes.KIOSK_CATEGORY_ITEMS_SUCCESS,
      KioskActionTypes.KIOSK_CATEGORY_ITEMS_FAILURE,
    ],
  }),
  publications: paginate({
    mapActionToKey: (action) => action.publicationId.toString(),
    types: [
      KioskActionTypes.PUBLICATION_REQUEST,
      KioskActionTypes.PUBLICATION_SUCCESS,
      KioskActionTypes.PUBLICATION_FAILURE,
    ],
  }),
  publicationIssues: paginate({
    mapActionToKey: (action) => action.publicationId.toString(),
    types: [
      KioskActionTypes.PUBLICATION_ISSUES_REQUEST,
      KioskActionTypes.PUBLICATION_ISSUES_SUCCESS,
      KioskActionTypes.PUBLICATION_ISSUES_FAILURE,
    ],
  }),
});
