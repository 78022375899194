import { join } from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { resettingResetPassword } from "store/actions/resetting";

function ForgotPassword(props) {
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const { token } = useParams();

  useEffect(() => {
    document.body.classList.add("authentication");
  }, []);

  useEffect(() => {
    if (token) {
    }
  }, [token]);

  useEffect(() => {
    if (props.resetting?.response?.token) {
      props.history.push("/");
    }
  }, [props.resetting?.response?.token, props.history]);

  const resetPassword = (e) => {
    e?.preventDefault();
    const data = {
      // email,
      password,
      confirmation: confirmationPassword,
      kiosk: process.env.REACT_APP_KIOSK_ID,
    };
    props.resettingResetPassword(token, data);
  };
  const getFieldError = (field) =>
    join(
      props.resetting?.response?.errors?.form?.children?.plainPassword
        ?.children[field]?.errors,
      "/n"
    );

  const firstError = getFieldError("first");
  const secondError = getFieldError("second");
  return (
    <div className="container-fluid h-100 authentication mb-0">
      <div className="row p-0 h-100">
        <div className="col-md-7 col-sm-4 random-image"></div>
        <div className="col-12 col-md-5 col-sm-8">
          <div className="paper">
            <i className="fa fa-lock p-3 rounded-circle border-secondary border text-secondary"></i>
            <h1 className="text-secondary">Réinitialisation mot de passe</h1>
            <form
              className="form needs-validation"
              onSubmit={resetPassword}
              noValidate
            >
              {props.resetting.error && (
                <div className="alert alert-danger" role="alert">
                  {props.resetting.error}
                </div>
              )}
              {props.resetting?.response?.message && !props.resetting.error && (
                <div className="alert alert-success" role="alert">
                  {props.resetting?.response?.message}
                </div>
              )}

              <div>
                <label htmlFor="inputPassword">Nouveau mot de passse</label>
                <input
                  type="password"
                  name="passzord"
                  id="inputPassword"
                  className={`form-control flat ${
                    firstError ? "is-invalid" : ""
                  }`}
                  autoComplete="new-password"
                  required
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={props.resetting.fetching}
                />
                {firstError && (
                  <div className="invalid-feedback">{firstError}</div>
                )}
              </div>

              <div>
                <label htmlFor="inputCPassword">
                  Confirmation nouveau mot de passse
                </label>
                <input
                  type="password"
                  name="inputCPassword"
                  id="inputCPassword"
                  className={`form-control flat ${
                    secondError ? "is-invalid" : ""
                  }`}
                  autoComplete="new-password"
                  required
                  autoFocus
                  value={confirmationPassword}
                  onChange={(e) => setConfirmationPassword(e.target.value)}
                  disabled={props.resetting.fetching}
                />
                {secondError && (
                  <div className="invalid-feedback">{secondError}</div>
                )}
              </div>

              <button
                type="submit"
                className="w-100 btn btn-lg btn-secondary flat my-2"
              >
                {props.resetting.fetching && (
                  <>
                    <i className="fa fa-spinner fa-spin p-3  text-light" />
                    &nbsp;&nbsp;
                  </>
                )}
                Je réinitialise mon mot de passe
              </button>

              <div className="row my-2 p-0 text-center">
                <div className="col-12  ">
                  <a href="/signin" className="text-dark">
                    Se connecter
                  </a>
                </div>
                <div className="col-12  ">
                  <Link to="/signup" className="text-dark">
                    Je crée mon compte
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { auth, profile, resetting } = state;

  return {
    auth,
    user: profile.user,
    resetting: resetting.resetPassword,
  };
};
export default connect(mapStateToProps, { resettingResetPassword })(
  ForgotPassword
);
