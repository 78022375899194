import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { resettingRequestPassword } from "store/actions/resetting";

function ResettingRequestPassword(props) {
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.body.classList.add("authentication");
  }, []);

  const requestNewPassword = (e) => {
    e?.preventDefault();
    const data = {
      email,
      kiosk: process.env.REACT_APP_KIOSK_ID,
    };
    props.resettingRequestPassword(data);
  };

  return (
    <div className="container-fluid h-100 authentication mb-0">
      <div className="row p-0 h-100">
        <div className="col-md-7 col-sm-4 random-image"></div>
        <div className="col-12 col-md-5 col-sm-8">
          <div className="paper">
            <i className="fa fa-lock p-3 rounded-circle border-secondary border text-secondary"></i>
            <h1 className="text-secondary">Mot de passe oublié</h1>
            <form className="form" onSubmit={requestNewPassword} noValidate>
              {props.resetting.error && (
                <div className="alert alert-danger" role="alert">
                  {props.resetting.error}
                </div>
              )}
              {!props.resetting.error && props.resetting.response?.message && (
                <div className="alert alert-success" role="alert">
                  {props.resetting.response?.message}
                </div>
              )}
              <label htmlFor="inputEmail">Email</label>
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control flat"
                autoComplete="username"
                required
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={props.resetting.fetching}
              />
              <button
                type="submit"
                className="w-100 btn btn-lg btn-secondary flat my-2"
              >
                {props.resetting.fetching && (
                  <>
                    <i className="fa fa-spinner fa-spin p-3  text-light" />
                    &nbsp;&nbsp;
                  </>
                )}
                Envoyer
              </button>
              {/* <div className="or-horizontal my-2">Ou</div> */}
              {/* <div className="d-flex flex-row align-items-center justify-content-between flex-wrap my-2">
                <a
                  href="/oauth_connect/google"
                  style="background-color: #3E5EA1;"
                  className="btn btn-lg btn-block btn-rounded text-white"
                >
                  <span className="fab fa-facebook-square"></span>
                  <span>Facebook</span>
                </a>
                <a
                  href="/oauth_connect/google"
                  style="background-color: #d34836;"
                  className="btn btn-lg btn-block btn-rounded text-white"
                >
                  <span className="fab fa-google"></span>
                  <span>Google</span>
                </a>
                <a
                  href="/oauth_connect/google"
                  style="background-color: #2AA8E0;"
                  className="btn btn-lg btn-block btn-rounded text-white"
                >
                  <span className="fab fa-twitter-square"></span>
                  <span>Twitter</span>
                </a>
              </div> */}
              <div className="row my-2 p-0 text-center">
                <div className="col-12  ">
                  <a href="/signin" className="text-dark">
                    Se connecter
                  </a>
                </div>
                <div className="col-12  ">
                  <Link to="/signup" className="text-dark">
                    Je crée mon compte
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { auth, profile, resetting } = state;

  return {
    auth,
    user: profile.user,
    resetting: resetting.requestPassword,
  };
};
export default connect(mapStateToProps, { resettingRequestPassword })(
  ResettingRequestPassword
);
