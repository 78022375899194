import { useState, useEffect, useCallback } from "react";

const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleScroll = useCallback(() => {
    // console.log(
    //   window.innerHeight,
    //   document.documentElement.scrollTop,
    //   window.innerHeight + document.documentElement.scrollTop,
    //   document.documentElement.offsetHeight
    // );
    // if (
    //   window.innerHeight + document.documentElement.scrollTop ===
    //     document.documentElement.offsetHeight &&
    //   !isFetching
    // ) {
    //   setIsFetching(true);
    // }

    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (scrollTop + clientHeight >= scrollHeight - 5 && !isFetching) {
      setIsFetching(true);
    }
    return;
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!isFetching) return;
    callback(() => {
      // console.log("called back");
    });
  }, [callback, isFetching]);

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
