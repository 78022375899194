import axios from "axios";
import { AUTHENTICATION_SUCCESS, profile } from "store/actions/authentication";
import { CONFIRM_REGISTRATION_SUCCESS } from "store/actions/registration";
import { RESETTING_RESET_SUCCESS } from "store/actions/resetting";
import { UPDATE_USER_SUCCESS } from "store/actions/user";

// A Redux middleware that interprets actions with DOWNLOAD_ISSUE info specified.
// Performs the call and promises when such actions are dispatched.
const profileMiddleware = (store) => (next) => (action) => {
  if (
    [
      AUTHENTICATION_SUCCESS,
      UPDATE_USER_SUCCESS,
      RESETTING_RESET_SUCCESS,
      CONFIRM_REGISTRATION_SUCCESS,
    ].indexOf(action?.type) !== -1
  ) {
    if (action?.response?.token) {
      axios.defaults.headers.common.Authorization = action.response.token;
    }
    store.dispatch(profile());
  }
  return next(action);
};

export default profileMiddleware;
