import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { confirmRegistration } from "store/actions/registration";
import { Link, useParams } from "react-router-dom";

const AccountConfirmation = (props) => {
  const { token } = useParams();
  const confirmation = useSelector((state) => state.confirmRegistration);
  const { fetching = true, error } = confirmation;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(confirmRegistration(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (confirmation?.response?.token) {
      props.history.push({
        pathname: "/",
      });
    }
  }, [confirmation?.response?.token, props.history]);
  return (
    <div className="container d-flex flex-column justify-content-center h-100 align-items-center">
      {fetching ? (
        <h5>Activation de votre compte</h5>
      ) : (
        error && (
          <div className="my-4 text-center">
            <h5 className="text-danger">
              Un probleme est survenue lors de l'activation de votre compte.
            </h5>
            <p>
              Veuillez reessayer. si le probleme persite merci de nous contact
              par email contact@propress.fr
            </p>
            <Link
              to="/"
              replace
              className="btn btn-outline-dark btn-lg "
              data-bs-toggle="button"
            >
              <i className="fa fa-home " /> Retour à l'accueil
            </Link>
          </div>
        )
      )}

      {fetching && (
        <>
          <p>Veuillez patienter</p>
          <i className="fas fa-spinner fa-spin fa-10x" />
        </>
      )}
      {error && (
        <>
          <i className="fas fa-exclamation-circle fa-10x text-danger" />
        </>
      )}
    </div>
  );
};

export default connect()(AccountConfirmation);
