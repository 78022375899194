// import logo from "images/logo.png";
import logo from "images/groupe-propress-h.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-primary fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo Le Kiosque Numerique" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              {/* <li className="nav-item">
                <Link className="nav-link" to="/">
                  Kiosque
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Bibliotheque
                </Link>
              </li> */}
            </ul>
            {/* <form className="d-flex">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
            <ul className="navbar-nav d-flex">
              <Link className="nav-link" to="/">
                Kiosque
              </Link>
              {!props.user && (
                <li className="nav-item">
                  <Link className="nav-link" to="/signin">
                    Connexion
                  </Link>
                </li>
              )}
              {props.user && !props.user.default_user && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/account">
                      Mon compte
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link text-danger" to="/logout">
                      Se deconnecter
                    </Link>
                  </li> */}
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  const {
    entities: { categories },
    profile,
  } = state;

  return {
    categories,
    user: profile.user,
  };
};
export default connect(mapStateToProps)(Header);
