import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { authenticate } from "store/actions/authentication";

function SignIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.body.classList.add("authentication");
  }, []);
  useEffect(() => {
    if (props.user) {
    }
  }, [props.user]);
  const authenticate = (e) => {
    e?.preventDefault();
    const data = {
      email,
      password,
      kiosk: process.env.REACT_APP_KIOSK_ID,
    };
    props.authenticate(data);
  };

  if (props.user) {
    return <Redirect to="/" />;
  }
  return (
    <div className="container-fluid h-100 authentication mb-0">
      <div className="row p-0 h-100">
        <div className="col-md-7 col-sm-4 random-image"></div>
        <div className="col-12 col-md-5 col-sm-8">
          <div className="paper">
            <i className="fa fa-lock p-3 rounded-circle border-secondary border text-secondary"></i>
            <h1 className="text-secondary">Connexion</h1>
            <form className="form" onSubmit={authenticate} noValidate>
              {props.auth.error && (
                <div className="alert alert-danger" role="alert">
                  {props.auth.error}
                </div>
              )}
              <label htmlFor="inputEmail">Email</label>
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control flat"
                autoComplete="username"
                required
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={props.auth.authenticatting}
              />
              <label htmlFor="inputPassword">Password</label>
              <input
                type="password"
                autoComplete="current-password"
                name="password"
                id="inputPassword"
                className="form-control flat"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={props.auth.authenticatting}
              />
              <button
                type="submit"
                className="w-100 btn btn-lg btn-secondary flat my-2"
              >
                {props.auth.authenticatting && (
                  <>
                    <i className="fa fa-spinner fa-spin p-3  text-light" />
                    &nbsp;&nbsp;
                  </>
                )}
                Sign In
              </button>
              {/* <div className="or-horizontal my-2">Ou</div> */}
              {/* <div className="d-flex flex-row align-items-center justify-content-between flex-wrap my-2">
                <a
                  href="/oauth_connect/google"
                  style="background-color: #3E5EA1;"
                  className="btn btn-lg btn-block btn-rounded text-white"
                >
                  <span className="fab fa-facebook-square"></span>
                  <span>Facebook</span>
                </a>
                <a
                  href="/oauth_connect/google"
                  style="background-color: #d34836;"
                  className="btn btn-lg btn-block btn-rounded text-white"
                >
                  <span className="fab fa-google"></span>
                  <span>Google</span>
                </a>
                <a
                  href="/oauth_connect/google"
                  style="background-color: #2AA8E0;"
                  className="btn btn-lg btn-block btn-rounded text-white"
                >
                  <span className="fab fa-twitter-square"></span>
                  <span>Twitter</span>
                </a>
              </div> */}
              <div className="row my-2 p-0 text-center">
                <div className="col-12  ">
                  <Link to="/forgot-password" className="text-dark">
                    Mot de passe oublié?
                  </Link>
                </div>
                <div className="col-12  ">
                  <Link to="/signup" className="text-dark">
                    Vous n'avez pas encore de compte?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { auth, profile } = state;

  return {
    auth,
    user: profile.user,
  };
};
export default connect(mapStateToProps, { authenticate })(SignIn);
