import { forEach, map, merge, split } from "lodash";
import { DownloadOnTheStore, Issue } from "components";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadCategories } from "store/actions/kiosk";
import { useParams } from "react-router-dom";
import { updateUser } from "store/actions/user";
import { freeDays } from "store/actions/authentication";
import readerUrl from "config/readerUrl";

function Kiosk(props) {
  const { isFetching, isAuthenticated, location, history } = props;
  const dispatch = useDispatch();
  const { offer } = useParams();
  console.log(
    offer,
    useSelector((state) => state.freeDays)
  );
  useEffect(() => {
    const getParameters = () => {
      let params = {},
        requestParams = {};
      const { search, hash } = location;
      requestParams = merge(split(search, "#"), split(search, "?"));
      requestParams = merge(requestParams, split(hash, "?"));
      requestParams = merge(requestParams, split(hash, "?"));
      // requestParams = _.merge(requestParams, _.split(hash,'#'));

      forEach(requestParams, (item, i) => {
        let splited = split(item, "=");
        if (splited.length === 2) {
          params[splited[0]] = splited[1];
        }
      });

      return params;
    };
    const params = getParameters();
    const {
      recover_token,
      confirmation_token,
      resetingPasswordUrl,
      ppc_qrcode,
    } = params;

    if (recover_token) {
      history.push({
        pathname: `/reset-password/${recover_token}`,
      });
    } else if (confirmation_token) {
      history.replace({
        pathname: `/email-confirmation/${confirmation_token}`,
      });
    } else if (resetingPasswordUrl) {
    } else if (ppc_qrcode) {
      // history.push({ pathname: "/register", query: { code: ppc_qrcode } });
    }
  }, [location, history]);

  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);
  useEffect(() => {
    if (["gv", "gvlc"].indexOf(offer) !== -1) {
      let countDays = 30;
      if (offer === "gvlc") {
        countDays = 90;
      }

      if (isAuthenticated) {
        dispatch(
          updateUser({
            options: [
              {
                freeDays: countDays,
              },
            ],
          })
        );
      } else {
        dispatch(freeDays(countDays));
      }
    }
  }, [offer, isAuthenticated, dispatch]);
  const onClick = (issueItem) => {
    if (isAuthenticated && issueItem) {
      window.location.href = readerUrl(issueItem);
    } else {
      props.history.push("/signin");
    }
  };
  return (
    <div className="min-vh-100">
      <div className="mt-3" />

      {/* {!props.isAuthenticated && <DownloadOnTheStore />} */}
      <DownloadOnTheStore />

      {isFetching && (
        <div className="container d-flex justify-content-center min-vh-100 align-items-center">
          <i className="fas fa-spinner fa-spin fa-10x" />
        </div>
      )}

      <div className="container">
        {map(props.categories, (category) => {
          return (
            <div
              className="category mt-4"
              key={category.id}
              id={`category-${category.id}`}
            >
              <div className="mb-4">
                <h4 className="text-secondary">{category.name}</h4>
                <h6>{category.subtitle}</h6>
              </div>
              <div className="row m-0">
                {map(category.items, (itemId) => {
                  const categoryItem = props.categoryItems[itemId];
                  const issueId = categoryItem?.publicationNumber || itemId;
                  return (
                    <div key={itemId} className="col-6 col-sm-4 col-md-2 p-1">
                      <Issue
                        issueId={issueId}
                        onClick={(e) => {
                          // console.log(issueId);
                          // window.location.href = readerUrl(props.issues[issueId]);
                          onClick(props.issues[issueId]);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    entities: { categories, categoryItems, issues },
    pagination: { kioskCategories },
  } = state;

  return {
    categories,
    categoryItems,
    issues,
    isFetching:
      kioskCategories[process.env.REACT_APP_KIOSK_ID]?.isFetching || false,
    isAuthenticated: Boolean(state?.profile?.user),
  };
};
export default connect(mapStateToProps)(Kiosk);
