import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { loadIssue } from "store/actions/kiosk";
import imageNotFound from "images/image-not-found.png";
import { Link, Redirect, useLocation } from "react-router-dom";
const Issue = (props) => {
  const { issue, onClick } = props;
  const [redirect] = useState();
  const _imgRef = useRef();

  useEffect(() => {
    if (props.issueId) {
      props.loadIssue(props.issueId, ["coverUrl"]);
    }
  }, [props, props.issueId]);

  const onError = (e) => {
    e.target.src = imageNotFound;
  };

  const location = useLocation();

  if (!issue) {
    return null;
  }
  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <div className="issue" onClick={onClick}>
      {/* <Link to={`/publication/${issue.publicationId}`}> */}
        <img
          ref={_imgRef}
          alt={"Issue"}
          loading="lazy"
          data-not-found
          className="img img-fluid img-lg"
          src={issue?.coverUrl}
          onError={onError}
        />

        <div className="info">
          <p className="title ">{issue?.publicationName}</p>
          <p className="published_at">
            {issue?.publishedAt
              ? moment(issue?.publishedAt).format("LL")
              : "\u00A0"}
          </p>
        </div>
      {/* </Link> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { issue: passIssueProp, issueId, isReadeable, disabled } = ownProps;
  const {
    entities: { issues },
    favoritePublications,
  } = state;
  const identifier = passIssueProp?.id || issueId;

  const issue = issues[identifier] || passIssueProp;
  const librearyItem = state.library[identifier];
  return {
    libraryItem: librearyItem,
    issue: issue,
    isReadeable:
      typeof isReadeable === "boolean"
        ? isReadeable
        : librearyItem && librearyItem.completed,
    issueId: identifier,
    isFavorite: favoritePublications.indexOf(issue?.publicationId) !== -1,
    disabled: typeof disabled === "boolean" ? disabled : !state.auth.token,
  };
};
export default connect(mapStateToProps, { loadIssue })(Issue);
